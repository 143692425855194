export default {
    namespaced: true,
    state() {
        return {
            sidebarOpened: !!+window.localStorage.sidebarOpened || false
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarOpened = !state.sidebarOpened;
            window.localStorage.sidebarOpened = '' + +state.sidebarOpened;
        },
        setter(state, {key, value}) {
            state[key] = value;
            window.localStorage.sidebarOpened = '' + +state.sidebarOpened;
        }
    },
}
