import Vue from 'vue';
import lang from '../config/lang';
export default class LangController {
    static langsList = [];
    static defaultLang = lang.DEFAULT_LANG;
    static defaultLangName = lang.DEFAULT_LANG_NAME;
    #lang = Vue.observable({value: LangController.defaultLang});
    #iconsCache = {};

    static setLangsList(langList) {
        this.langsList = langList;
    }
    static get langsArray() {
        return this.langsList ? Object.entries(this.langsList).map(lang => ({name: lang[1], value: lang[0]})) : [];
    }
    get lang() {
        return this.#lang.value;
    }
    set lang(value) {
        this.#lang.value = value;
    }
    getIcon() {
        if(!(this.lang in this.#iconsCache)) {
            this.#iconsCache[this.lang] = require(`@/assets/img/${this.lang}.svg`);
        }
        return this.#iconsCache[this.lang];
    }
    getList() {
        return LangController.langsList;
    }
    getListKeys() {
        return Object.keys(this.getList());
    }
    nextLang() {
        let lang = 'uz';
        const langKeys = this.getListKeys();
        const idx = langKeys.findIndex(key => key === this.lang);
        if(idx > -1) {
            lang = langKeys[idx + 1];
            if(!lang) {
                lang = langKeys[0];
            }
        }
        this.lang = lang;
    }
    setLang(lang) {
        const list = this.getList();
        if(lang in list) {
            this.lang = lang;
        } else {
            console.error('Не удается изменить язык', lang);
        }
    }
}
export const getNextLang = (lang) => {
    let lng = lang;
    if (lng === "qr") {
        lng = "qq";
    }
    const langKeys = Object.keys(LangController.langsList);
    const idx = langKeys.findIndex(key => key === lng);
    if(idx > -1) {
        lng = langKeys[idx + 1];
        if(!lng) {
            lng = langKeys[0];
        }
    }
    return lng;
}
