export const roles = {
    admin: 'admin',
    manager: 'manager',
    operator: 'operator',
    marketing: 'marketing',
    dib: 'dib'
};

// Only inside components
export const isSidebarVisible = function() {
    return [roles.admin, roles.manager, roles.marketing].includes(this.$store.state.auth.role);
}
