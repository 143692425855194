import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { DEFAULT_APP_LANGUAGE } from "@/config/lang";
import Localstorage from "@/helpers/localstorage";

Vue.use(VueI18n);

/* Dynamic imports all JSON files from the `locales` directory using Webpack functionality. */
const localeFiles = require.context('../locales', false, /\.json$/);
const messages = localeFiles.keys().reduce((accumulatedMessages, localeFilename) => {
    // Remove prefix and extension to derive the locale key
    const localeKey = localeFilename.replace('./', '').replace('.json', '');

    // Add the file content to the messages object
    accumulatedMessages[localeKey] = localeFiles(localeFilename);
    return accumulatedMessages;
}, {});

const locale = Localstorage.getItem("locale") ?? DEFAULT_APP_LANGUAGE;

const i18nOptions = {
    locale: locale,
    fallbackLocale: locale,
    messages
};

export default new VueI18n(i18nOptions);
