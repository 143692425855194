import {AGENT_CHAT_STATE} from "@contact-center/asakabank-cisco-finesse/dist/lib/finesse";
import {ChatRoom} from "../../services/chat/ChatModel";
import Vue from 'vue';

export default {
    namespaced: true,
    state() {
        return {
            incomingChat: null,
            operatorState: AGENT_CHAT_STATE.NOT_READY,
            changeLayoutAnimationDelay: 300, // Полная анимация длится 500 мс, по 250 на каждый. Здесь 300 берется с запасом
            activeChatId: null,
            /**
             * @type { {[key: string]: ChatRoom} }
             */
            chatsData: {},

            reloadChatTimer: null
        }
    },
    mutations: {
        changeOperatorState(state, s) {
            if(!s) return;
            state.operatorState = s;
        },

        updateIncomingChat(state, chat = null) {
            state.incomingChat = chat;
        },

        addChatData(state, chat) {
            Vue.set(state.chatsData, chat.chatRoom, chat);
        },
        removeChatData(state, chat) {
            Vue.delete(state.chatsData, chat.chatRoom);
        },
        setActiveChat(state, chatId = null) {
            state.activeChatId = chatId;
        },

        updateChat(state, chatData) {
            const data = state.chatsData[chatData.chatRoom];
            Object.entries(chatData).forEach(([key, value]) => {
                Vue.set(data, key, value);
            });
        },
        updateCustomer(state, { chatRoom, customerData }) {
            const customerId = customerData.id;
            const customer = state.chatsData[chatRoom].customers.find(customer => customer.id === customerId);
            Object.entries(customerData).forEach(([key, value]) => {
                Vue.set(customer, key, value);
            });
        },
        addChatMessage(state, message) {
            const chatData = state.chatsData[message.chatRoom];
            chatData.messages.push(message);
            chatData.unreadMessages++;
        },
        addChatMessagesList(state, {chatRoom, messages}) {
            state.chatsData[chatRoom].messages.push(...messages);
        },

        setReloadChatTimer(state, timer = null) {
            state.reloadChatTimer = timer;
        }
    },
    getters: {
        activeChatData(state) {
            return state.chatsData[state.activeChatId];
        }
    }
}
