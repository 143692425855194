import {getModifiedUserData} from "../../helpers/other";

/**
 * @typedef {{type: 'a'|'m'|'s', phone: string, id: number}} customerPhone
 */

export class CustomerData {
    bank_client_id = ''
    birthday = ''
    client_type = null
    code = ''
    /**
     * @type {customerPhone[]}
     */
    customer_phones = []
    email = ''
    gender = ''
    id = ''
    is_abs_active = 0
    is_company = 0
    passport_info = ''
    passport_no = ''
    pinfl = ''
    tin = ''
    username = ''

    /**
     * @constructor
     * @param {any} data
     * @param {string} incomingPhone
     */
    constructor(data, incomingPhone) {
        if(data) {
            Object.entries(getModifiedUserData(data, incomingPhone)).forEach(([key, value]) => this[key] = value);
        } else {
            this.customer_phones = [{ id: 0, phone: incomingPhone, type: 'm' }];
        }
    }
}
