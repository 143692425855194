import store from "../index";
const gallery = {
    namespaced: true,
    state() {
        return {
            visible: false,
            data: []
        }
    },
    mutations: {
        setGalleryData(state, data) {
            state.data = data;
        },
        resetGalleryData(state) {
            state.data = [];
        },
        openGallery(state) {
            state.visible = true;
        },
        closeGallery(state) {
            state.visible = false;
        }
    },
    actions: {
        closeGalleryAndClearData(context) {
            return new Promise((resolve) => {
                context.commit('closeGallery');
                setTimeout(() => {
                    context.commit('resetGalleryData');
                    resolve();
                }, 300);
            })
        },
        openGalleryAndLoadData(context, data) {
            return new Promise((resolve) => {
                context.commit('setGalleryData', data);
                requestAnimationFrame(() => {
                    context.commit('openGallery');
                    resolve();
                });
            })
        }
    }
}
export const galleryController = {
    open(images) {
        store.dispatch('gallery/openGalleryAndLoadData', images);
    },
    close() {
        store.dispatch('gallery/closeGalleryAndClearData');
    }
}
export default gallery;
