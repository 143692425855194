/***
 * @typedef ModalNames
 * @type {'filialsList', 'leave', 'report', 'absCustomer'}
 */
const modals = {
    namespaced: true,
    state() {
        return {
            modals: {
                filialsList: false,
                leave: false,
                modalFullscreen: false,
                report: false,
                absCustomer: false
            }
        }
    },
    mutations: {
        /***
         * @param {ModalNames} modalName
         ***/
        openModal(state, modalName) {
            state.modals[modalName] = true;
        },
        /***
         * @param {ModalNames} modalName
         ***/
        closeModal(state, modalName) {
            state.modals[modalName] = false;
        },
        toggleModalFullscreen(state) {
            state.modals.modalFullscreen = !state.modals.modalFullscreen;
        }
    }
}

export default modals;
