import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { BootstrapVue } from 'bootstrap-vue'
import antDesign from 'ant-design-vue';
import ElementUI from "element-ui";
// @ts-ignore
import VueMask from 'v-mask';
// @ts-ignore
import VueMoment from 'vue-moment'
// @ts-ignore
import VueFlashMessage from 'vue-flash-message';
// @ts-ignore
import Highcharts from 'highcharts';
// @ts-ignore
import VueHighcharts from 'vue2-highcharts'
// @ts-ignore
import VueTheMask from 'vue-the-mask'
// @ts-ignore
import Vuelidate from 'vuelidate'
// @ts-ignore
import locale from 'element-ui/lib/locale/lang/ru-RU'
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2';
import i18n from "./config/i18nSetup.js";
import "@/config/moment-uz-locale";

// Note: Vue automatically prefixes the directive name with 'v-'


// Global instruction


/* import styles */
import "element-ui/lib/theme-chalk/index.css";
import 'ant-design-vue/dist/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import TooltipDirective from "./directives/tooltip";
require('vue-flash-message/dist/vue-flash-message.min.css');

[Vuelidate, VueTheMask, antDesign, VueMoment, BootstrapVue, CoreuiVue,CKEditor,VueMask].forEach(x => Vue.use(x))
Vue.use(ElementUI, { locale })
Vue.use(VueHighcharts, { Highcharts });
Vue.use(VueFlashMessage, { messageOptions: { timeout: 5000 } });
Vue.config.performance = true;
Vue.prototype.$log = console.log.bind(console)

Vue.directive('tooltip', new TooltipDirective());

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App/>',
    components: { App },
})

export default app;
