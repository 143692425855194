export const AGENT_CALL_STATES = {
    TALKING: "1",
    READY: "3",
    RESERVED: "7",
    PAUSE: "27",
    LUNCH: "28",
    POST_PROCESSING: "30",
    OVERTIME: "36",
    NOT_READY: "37",
    TRAINING: "39"
};

export const OLD_STATUS_VALID_STATES = [
    AGENT_CALL_STATES.READY,
    AGENT_CALL_STATES.NOT_READY,
    AGENT_CALL_STATES.PAUSE,
    AGENT_CALL_STATES.TRAINING,
    AGENT_CALL_STATES.POST_PROCESSING,
    AGENT_CALL_STATES.LUNCH
];

export function returnToOldStatusNotReadyCheck(oldStatus) {
    return (
        oldStatus == AGENT_CALL_STATES.NOT_READY ||
        oldStatus == AGENT_CALL_STATES.PAUSE ||
        oldStatus == AGENT_CALL_STATES.TRAINING ||
        oldStatus == AGENT_CALL_STATES.LUNCH ||
        oldStatus == AGENT_CALL_STATES.POST_PROCESSING
    );
}
export function returnToOldStatusWorkCheck(callStatus) {
    return (
        callStatus == AGENT_CALL_STATES.TALKING ||
        callStatus == AGENT_CALL_STATES.RESERVED
    );
}
