export default {
    state: {
        messages: [],
    },
    mutations: {
        putMessages(state, payload) {
            if (!Array.isArray(payload)) {
                payload = [payload];
            }
            payload.forEach((item) => {
                const aMessageById = state.messages.find((aMessageItem) => {
                    return aMessageItem.id === item.id;
                });
                if (typeof aMessageById === 'undefined') {
                    state.messages.unshift(item);
                }
            });
        },
        removeMessage(state, id) {
            const idx = state.messages.findIndex(message => message.id === id);
            if(idx > -1) {
                state.messages.splice(idx, 1);
            }
        }
    }
}
