import {Module} from 'vuex';
import RootState from "@/types/store";

interface ProblemsType {
    createdProblems: number[],
    nonEditableProblems: number[]
}

const problemsStore: Module<ProblemsType, RootState> = {
    namespaced: true,
    state() {
        return {
            createdProblems: [],
            nonEditableProblems: []
        }
    },
    mutations: {
        addProblem(state, id: number) {
            state.createdProblems.push(id);
        },
        removeProblem(state, id: number) {
            state.createdProblems.splice(state.createdProblems.indexOf(id), 1);
        },
        clearProblems(state) {
            state.createdProblems = [];
        },
        addNonEditableProblem(state, id: number) {
            if(state.nonEditableProblems.includes(id)) return;
            state.nonEditableProblems.push(id);
        },
        clearNonEditableProblems(state) {
            state.nonEditableProblems = [];
        },
    }
}
export default problemsStore;
