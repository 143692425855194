import sAPIUrl from "../../config/api";

export default {
    state: {
        operator: {
            name: '',
            id: '',
            phone: '',
            photo: '/img/4.jpg',
            status: 0,
            role_id: 0,
            sticker: null
        }
    },
    mutations: {
        getOperatorData(state, payload) {
            state.operator = {
                name: payload?.first_name + " " + payload?.last_name || '',
                id: payload?.id || '',
                phone: payload?.phone || '',
                photo: payload.avatar ? `${sAPIUrl}storage/${payload.avatar}` : '/img/4.jpg',
                status: payload?.status || 0,
                role_id: payload?.role_id || 0,
                sticker: payload?.sticker || 1
            }
        },
        setOperatorSticker(state, sticker) {
            state.operator = {
                ...state.operator,
                sticker
            }
        },
        getAvatarImg(state, payload) {
            state.operator.photo = payload.avatar ? `${sAPIUrl}storage/${payload.avatar}` : ''
        }
    }
}
