import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import operator from "./modules/operator.jsx";
import teamMessages from "./modules/teamMessages";
import calculators from "./modules/calculators";
import tooltip from "./modules/tooltip";
import notifications from "./modules/notifications";
import modals from "./modules/modals";
import gallery from "./modules/gallery";
import chat from "./modules/chat";
import call from "./modules/call";
import activeOperations from "./modules/activeOperations";
import system from "./modules/system";
import customer from "./modules/customer";
import problems from "./modules/problems";
import LangController from "../services/LangController";
Vue.use(Vuex);

let get;

const state = {
    select_client_script: "",
    bye_script: "",
    isIncomingCall: false,
    search: true,
    marketing_sources: [],
    bankBranches: [],
    card_stat: 1,
    key: 1,
    applications_statuses: [],
    additional_number: [],
    dictionary: [],
    showClientCardTabs: false,
    clientCardTab: "ClientCard",
    status: 0,
    step: false,
    drop: false,
    isCalling: false,
    call: false,
    isOperatorPickedUpPhone: false,
    theme: [],
    callingNumber: "",
    whichNumber: "",
    statusArray: [],
    multiProblem: true,
    isLoading: false,
    creditObj: {},
    depositObj: {},
    selectedNumber: "",
    unknown: "",
    known: "",
    createApp: "",
    isCallOvertime: false,
    headerActiveTimerValue: 0,
    connectionError: false,
    serverTime: Date.now(),
    userTeams: [],
    filialsList: [],
    canChat: false,
    isChat: false,
    userData: {},
    searchError: false,
    searchLoading: false,
    chatScripts: [],
    incomingClientSources: [],

    langController: new LangController(),
    criticalLoaded: false,

    asakaMobilePaymentTypes: {},
    asakaMobileTransactionStatuses: {},
    asakaMobilePaymentTypesLoading: false,
    asakaMobilePaymentTypesFetched: false,

    langsById: {},

    autoList: {
        kia: []
    },
    instalmentCalculator: {},
    instalmentFetched: false,
    problemsLoading: false,

    applicationStatuses: {
        type: "default",
        default: [],
        outbound: []
    }
};

const mutations = {
    setProblemsLoading(state, status) {
        state.problemsLoading = status;
    },
    setCriticalDataLoaded(state) {
        state.criticalLoaded = true;
    },
    setIsCallOvertime(state, value) {
        state.isCallOvertime = value;
    },
    setIncomingClientSources(state, sources) {
        state.incomingClientSources = sources;
    },
    userCanChat(state) {
        state.canChat = true;
    },
    changeChatLayout(state, isChat) {
        state.isChat = isChat;
    },
    setFilialsList(state, list) {
        state.filialsList = list;
    },
    setUserTeams(state, teams) {
        state.userTeams = teams;
    },
    setServerTime(state, time) {
        state.serverTime = time;
    },
    emitConnectionError(state) {
        state.connectionError = true;
    },
    setCreditObj(state, obj) {
        state.creditObj = obj;
    },
    setDepositObj(state, obj) {
        state.depositObj = obj;
    },
    updateHeaderActiveTimerValue(state, value) {
        state.headerActiveTimerValue = value;
    },
    changeisIncomingCall(state, payload) {
        state.isIncomingCall = payload;
    },
    getcreateApp(state, payload) {
        state.createApp = payload;
    },
    getKey(state) {
        state.key = !state.key;
    },
    getunknown(state, payload) {
        state.unknown = payload;
    },
    getknown(state, payload) {
        state.known = payload;
    },
    getbye_script(state, payload) {
        state.bye_script = payload;
    },
    getSelectClientScript(state, payload) {
        state.select_client_script = payload;
    },
    getSelectedNumber(state, payload) {
        state.selectedNumber = payload;
    },
    getdictionary(state, payload) {
        state.dictionary = payload;
    },
    updateBankBranches(state, payload) {
        state.bankBranches = payload;
    },
    getstatusArray(state, payload) {
        state.statusArray = payload;
    },
    getproblem(state, action) {
        state.multiProblem = action;
    },
    getLoading(state, newstate) {
        state.isLoading = newstate;
    },
    getsearch(state, _) {
        state.search = !state.search;
    },
    getTheme(state, newtheme) {
        state.theme = newtheme;
    },
    getCall(state, newCall) {
        state.call = newCall;
    },
    setOperatorPickedUpPhone(state, status) {
        state.isOperatorPickedUpPhone = status;
    },
    set(state, [variable, value]) {
        state[variable] = value;
    },
    changeStatus(state, payload) {
        state.status = payload;
    },
    changeDrop(state, payload) {
        state.drop = payload;
    },
    checkCalling(state, payload) {
        state.isCalling = payload;
    },
    getcard_stat(state, payload) {
        state.card_stat = payload;
    },
    clearUserData(state) {
        state.userData = {};
        state.selectedNumber = "";
    },
    getmarketing_sources(state, payload) {
        state.marketing_sources = payload;
    },
    getapplications_statuses(state, payload) {
        state.applications_statuses = payload;
    },
    setUserData(state, user) {
        state.userData = user;
    },
    setSearchError(state) {
        state.searchError = true;
    },
    clearSearchError(state) {
        state.searchError = false;
    },
    setSearchStart(state) {
        state.searchLoading = true;
    },
    setSearchEnd(state) {
        state.searchLoading = false;
    },
    setChatScripts(state, data) {
        state.chatScripts = data;
    },
    setAsakaMobilePaymentTypes(state, {types, statuses}) {
        if(types) {
            state.asakaMobilePaymentTypes = types;
        }
        if(statuses) {
            state.asakaMobileTransactionStatuses = statuses;
        }
        state.asakaMobilePaymentTypesFetched = true;
    },
    setAsakaMobilePaymentLoading(state, loading) {
        state.asakaMobilePaymentTypesLoading = loading;
    },
    clearState(state) {
        state.searchError = false;
        state.search = true;
        state.marketing_sources = [];
        state.card_stat = 1;
        state.applications_statuses = [];
        state.dictionary = [];
        state.showClientCardTabs = false;
        state.clientCardTab = "ClientCard";
        state.status = 0;
        state.step = false;
        state.drop = false;
        state.isCalling = false;
        state.call = false;
        state.theme = [];
        state.callingNumber = "";
        state.whichNumber = "";
        state.statusArray = [];
        state.multiProblem = true;
        state.isLoading = false;
        state.headerActiveTimerValue = 0;
        state.isCallOvertime = false;
        state.userData = {};
    },
    clearApplicationStatuses(state) {
        state.applicationStatuses = {
            type: "default",
            default: [],
            outbound: []
        };
    },
    setApplicationStatuses(state, applications) {
        state.applicationStatuses.default = applications.default;
        state.applicationStatuses.outbound = applications.outbound;
    },
    switchApplicationStatusesType(state, type) {
        state.applicationStatuses.type = type;
    }
};
const getters = {
    IS_OPERATOR_PICKER_UP_PHONE(state) {
        return state.isOperatorPickedUpPhone;
    },
    problemsLoading: state => state.problemsLoading,
    applicationStatuses: state => state.applicationStatuses[state.applicationStatuses.type],
    applicationStatusesForSelect: state => {
        const field = state.applicationStatuses[state.applicationStatuses.type];

        return Object.entries(field).map(entry => ({ name: entry[1], value: +entry[0] }));
    },
    applicationStatusesForFilter: state => {
        const field = state.applicationStatuses[state.applicationStatuses.type];

        return Object.entries(field).map(([key, value]) => ({id: +key, value: +key, name: value}));
    },
    applicationStatusesForOutbound: state => {
        const field = state.applicationStatuses["outbound"] ?? {};

        return Object.entries(field).map(([key, value]) => ({id: +key, value: +key, name: value}));
    },
    applicationStatusesForIncoming: state => {
        const field = state.applicationStatuses["default"] ?? {};

        return Object.entries(field).map(([key, value]) => ({id: +key, value: +key, name: value}));
    },
    applicationStatusList: state => state.applicationStatuses
};
const actions = {
    fetchAsakaMobilePaymentTypes({commit}) {
        commit("setAsakaMobilePaymentLoading", true);
        const arr = [
            get("/asaka-mobile/transaction-statuses"),
            get("/asaka-mobile/payment-types")
        ];
        return Promise.all(arr)
            .then(([statuses, types]) => {
                commit("setAsakaMobilePaymentTypes", {
                    types: types.data.data,
                    statuses: statuses.data.data
                });
            })
            .finally(() => {
                commit("setAsakaMobilePaymentLoading", false);
            });
    },
    fetchLangsById({state}) {
        if(Object.keys(state.langsById).length) return;

        get("/languages-by-id")
            .then(({data}) => {
                state.langsById = data;
            });
    },
    initGet() {
        import("../helpers/api")
            .then((data) => {
                get = data.get;
            });
    },
    fetchKiaList({state}) {
        if(state.autoList.kia.length) return;
        get("/auto/models/full-kia")
            .then(({data}) => {
                state.autoList.kia = data.models;
            });
    },
    fetchInstalmentCalculator({state}) {
        if(state.instalmentFetched) return;
        get("/calculator/kia-installment")
            .then(({data}) => {
                state.instalmentCalculator = data.data;
                state.instalmentFetched = true;
            });
    },
    async getApplicationStatuses(ctx) {
        const {commit} = ctx;

        try {
            const result = await Promise.all([
                get("applications/statuses"),
                get("applications/statuses?outbound=1")
            ]);

            commit("setApplicationStatuses", {
                default: result[0].data.data,
                outbound: result[1].data.data
            });
        }
        catch (e) {throw e;}
    }
};

const store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {
        auth,
        operator,
        teamMessages,
        calculators,
        tooltip, // namespaced
        notifications, // namespaced
        modals, // namespaced
        gallery, // namespaced
        chat, // namespaced
        callData: call, // namespaced
        activeOperations, // namespaced
        system, //namespaced
        customer, //namespaced
        problems
    }
});
store.dispatch("initGet");
export default store;
