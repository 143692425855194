const tooltipStore = {
    namespaced: true,
    state() {
        return {
            left: 0,
            top: 0,
            posVer: 'bottom',
            posHor: 'center',
            content: '',
            visible: false
        }
    },
    mutations: {
        openTooltip(state, options) {
            state.left = options.left;
            state.top = options.top;
            state.posVer = options.posVer;
            state.posHor = options.posHor;
            state.visible = true;
        },
        closeTooltip(state) {
            state.visible = false
        },
        setTooltipContent(state, content) {
            state.content = content;
        }
    }
}
export default tooltipStore;
