function checkTypeIsExist(type, list) {
    if (!!type) {
        const elementIndex = list.findIndex((item) => {
            return item.type === type
        });
        return (elementIndex < 0);
    }
}

export default {
    state: {
        credits: [],
        deposit: [],
        chatBot: false,
        applications: false,
        email: false,
        ffinSite: false
    },
    mutations: {
        putCreditCalcParams(state, payload) {
            const creditInformation = {
                type: payload.type ?? 0,
                amount: payload.amount ?? 0,
                month: payload.month ?? 0,
                prepayment: payload.prepayment ?? 0,
                repaymentScheme: payload.repaymentScheme ?? 0,
                annualRate: payload.percent ?? 0
            };
            if (!!checkTypeIsExist(creditInformation.type, state.credits)) {
                state.credits.push(creditInformation);
            }
        },
        putDepositCalcParams(state, payload) {
            const depositInformation = {
                type: payload.type ?? 0,
                currency: payload.currency ?? 0,
                amount: payload.amount ?? 0,
                month: payload.month ?? 0
            };
            if (!!checkTypeIsExist(depositInformation.type, state.deposit)) {
                state.deposit.push(depositInformation);
            }
        },
        putChatBotLink(state, payload) {
            state.chatBot = payload;
        },
        putFfinSiteLink(state, payload) {
            state.ffinSite = payload;
        },
        putApplicationsLink(state, payload) {
            state.applications = payload;
        },
        putEmailLink(state, payload) {
            state.email = payload;
        },
        clearCalcParamsNoCreditsDeposits(state) {
            state.chatBot = false;
            state.applications = false;
            state.email = false;
            state.ffinSite = false;
        },
        clearCalcParams(state) {
            state.credits = [];
            state.deposit = [];
            state.chatBot = false;
            state.applications = false;
            state.email = false;
            state.ffinSite = false;
        }
    }
}
