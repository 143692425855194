let id = 0;
const notifications = {
    namespaced: true,
    /***
     * @function storeState
     * @returns {{list: *[]}}
     */
    state() {
        return {
            list: []
        }
    },
    mutations: {
        /***
         * @param {Object} notification
         * @param {string} notification.message
         * @param {number} notification.id
         * @param {storeState} state
         *  ***/
        addFirst(state, notification) {
            notification.id = id++;
            state.list.unshift(notification)
        },
        removeLast(state) {
            state.list.pop();
        },
        remove(state, id) {
            let idx = state.list.findIndex(notification => notification.id === id);
            state.list.splice(idx, 1);
        },
        error(state, message) {
            const { title = message, text = "components.common.weHaveDroppedEverythingAgainGoToTheAdmin", type = "error" } = message; // If the key comes without parameters, then we substitute it in text

            const notification = {
                message: text,
                title,
                type,
            };

            this.commit('notifications/addFirst', notification);
        },
        add(state, {type, message}) {
            this.commit('notifications/addFirst', createNotification(type, message));
        }
    }
}
export default notifications;

const descriptions = {
    error: "components.common.weHaveDroppedEverythingAgainGoToTheAdmin",
    success: "requestsStatuses.success.index"
}

function createNotification(type, title) {
    if(type !== "error" && type !== "success") {
        throw new Error("Notification must be error or success");
    }
    const description = descriptions[type];

    return {
        type,
        title,
        message: description,
    }
}
