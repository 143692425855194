import Vue from "vue";
export default {
    namespaced: true,
    state() {
        return {
            absCustomers: {},
            activeAbsCustomer: null
        }
    },
    mutations: {
        addAbsCustomer(state, { bankClientId, data }) {
            Vue.set(state.absCustomers, bankClientId, data);
        },
        clearAbsCustomers(state) {
            state.absCustomers = {};
        },
        selectAbsCustomer(state, bankClientId) {
            state.activeAbsCustomer = bankClientId;
        },
    }
}
