class LocalStorage<T extends Record<string, any> > {
    private readonly _prefix: string;
    private _cache?: T;

    constructor(prefix: string = "") {
        this._prefix = prefix;
        this._cache = this.parse();
    }

    setItem<K extends keyof T>(key: K, value: T[K]): void {
        this._cache = {...this._cache, [key]: value} as T;
        localStorage.setItem(this._prefix, JSON.stringify(this._cache));
    }

    getItem<K extends keyof T>(key: K): T[K] | undefined {
        return this._cache?.[key];
    }

    removeItem<K extends keyof T>(key: K): void {
        if (this._cache) {
            delete this._cache[key];
            localStorage.setItem(this._prefix, JSON.stringify(this._cache));
        }
    }

    clear(): void {
        this._cache = undefined;
        localStorage.setItem(this._prefix, "");
    }

    private parse(): T {
        const data: string | null = localStorage.getItem(this._prefix);

        if (data) {
            return JSON.parse(data);
        }

        return {} as T;
    }
}

export default new LocalStorage("ALO_CRM");
