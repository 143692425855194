import Vue from "vue";
import { AGENT_CALL_STATES } from "@/config/call-states";

export default {
    namespaced: true,
    state() {
        return {
            incomingCall: null,
            operatorState: AGENT_CALL_STATES.NOT_READY,
            callModel: null,
            customerCallModel: null,
            apealForm: {
                theme_ids: [],
                branch_id: 0,
                status: 0,
                marketing_source: 0,
                description: "",
                type_id: 0
            }
        };
    },
    mutations: {
        setCall(state, callData) {
            state.callModel = callData;
        },
        updateCall(state, callData) {
            const data = state.callModel;
            Object.entries(callData).forEach(([key, value]) => {
                Vue.set(data, key, value);
            });
        },
        setCustomerCall(state, callData) {
            state.customerCallModel = callData;
        },
        updateCustomerCall(state, callData) {
            const data = state.customerCallModel;
            Object.entries(callData).forEach(([key, value]) => {
                Vue.set(data, key, value);
            });
        },
        changeOperatorState(state, s) {
            if(!s) return;
            state.operatorState = s;
        },
        updateIncomingCall(state, call) {
            state.incomingCall = call;
        },
        setAppelFormData(state, data) {
            if (data.theme_ids) {
                state.apealForm.theme_ids = data.theme_ids;
            }
            if (data.branch_id) {
                state.apealForm.branch_id = data.branch_id;
            }
            if (data.status) {
                state.apealForm.status = data.status;
            }
            if (data.marketing_source) {
                state.apealForm.marketing_source = data.marketing_source;
            }
            if (data.description) {
                state.apealForm.description = data.description;
            }
            if (data.type_id) {
                state.apealForm.type_id = data.type_id;
            }
        },
        resetAppealFormData(state) {
            state.apealForm = {
                theme_ids: [],
                branch_id: 0,
                status: 0,
                marketing_source: 0,
                description: "",
                type_id: 0
            };
        }
    }
};
