export default {
    state: {
        authenticated: false,
        token: null,
        role: null,
        username: null,
        phone: null,
        operatorData: {
            last_name: '',
            first_name: '',
            phone: ''
        }
    },
    mutations: {
        LOGGED_IN(state, {token, role, username, phone}) {
            state.token = token
            state.role = role
            state.username = username
            state.phone = phone
            state.authenticated = true
        },
        LOGGED_OUT(state) {
            state.authenticated = false
            state.token = null
            state.role = null
            state.username = null
            state.phone = null
        },
        operator_info(state, payload) {
            state.operatorData = payload;
        }
    }
}
