class Operation {
    constructor(data = {actions: {}, reasons: {}}) {
        this.actions = data.actions;
        this.reasons = data.reasons;
    }
}

export const activeOperationsCodes = {
    block: 'block',
    unblock: 'unblock',
    pin: 'pin',
    sms: 'sms',
    nosms: 'nosms',
    status: 'status'
}

const activeOperations = {
    namespaced: true,
    state() {
        return {
            uzcard: new Operation(),
            humo: new Operation(),
            visa: new Operation(),
            upi: new Operation(),
            mastercard: new Operation()
        }
    },
    mutations: {
        setProcessing(state, {key, value}) {
            state[key] = new Operation(value);
        },
    }
}
export default activeOperations;
