export const getPluralRU = (value, single, second, multi) => {
    const strValue = value.toString();
    if(isNaN(value)) return null;

    if(strValue.slice(-2) >= 5 && strValue.slice(-2) <= 20) {
        return multi;
    }
    const lastChar = strValue.slice(-1);

    if(lastChar == 1) return single;
    else if(lastChar >= 2 && lastChar <= 4) return second;
    else return multi;
};
// 1 картошкА, 2 картошкИ, 3 картошкИ, 4 картошкИ, 5 картошЕК, 11 картошек

const toNum = ["is_company", "client_type", "gender"];
export const getModifiedUserData = (data, incomingPhone) => {
    const user = {};
    for(let key in data) {
        let defaultValue = "";
        let value = data[key];
        if(typeof value === "string") {
            value = value.trim();
        }

        if(key === "customer_phones") {
            defaultValue = [];
            const mainPhoneIndex = value.findIndex(phone => phone.phone == incomingPhone);
            // Перемещаем телефон, с которого позвонили на первое место, чтобы в форме редактирования клиента он был на первом месте. На первом месте всегда телефон, с которого звонили
            if(mainPhoneIndex > -1) {
                value = [value[mainPhoneIndex], ...value.filter(phone => phone.phone != incomingPhone)];
            } else {
                value = [{id: 0, phone: incomingPhone}, ...value];
            }
        } else if(key === "birthday" && value) {
            value = new Date(value).toLocaleDateString("ru");
        }
        user[key] = typeof value === "number" || toNum.includes(key) ? +value : (value || defaultValue);
    }
    return user;
};

export const getPageNav = (userData) => {
    const arr = [
        {
            name: "common.customerCard",
            key: 1
        },
        {
            name: "common.requestHistory",
            key: 2
        },
        {
            name: "common.outgoingHistory",
            key: 6
        },
    ];
    if(userData.bank_client_id) {
        arr.push({
            name: "common.bankProducts",
            key: 4
        });
    }
    arr.push({
        name: "common.mobileApplication",
        key: 5
    });
    arr.push({
        name: "common.autoDebit",
        key: 7
    });
    return arr;
};

export const decodeHtmlToString = (text) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
}
