import app from "../main";
import {DirectiveFunction} from "vue/types/options";
import {DirectiveOptions} from "vue";
let showTimeout: ReturnType<typeof setTimeout> | null = null;
let tooltip: HTMLElement | null = null;
let onscrollActive = false;

const mouseIn: DirectiveFunction = (el, {value}) => {
    if(showTimeout) {
        clearTimeout(showTimeout);
    }

    showTimeout = setTimeout(() => {
        if(typeof value === 'function') {
            value = value();
        }
        if(!value) return;
        app.$store.commit('tooltip/setTooltipContent', value);

        app.$nextTick(() => {
            const bounds = el.getBoundingClientRect();

            if(!tooltip) return;

            const tooltipBounds = tooltip.getBoundingClientRect();
            const tooltipSides = Math.max(tooltipBounds.width - bounds.width, 0) / 2

            let directionVer = 'bottom';
            let directionHor = 'center'
            let top = bounds.bottom;
            let left = bounds.left + bounds.width / 2 - tooltipBounds.width / 2;

            if(window.innerHeight - bounds.bottom - 40 < tooltipBounds.height) {
                directionVer = 'top';
                top = bounds.top - tooltipBounds.height;
            }

            if(bounds.left + bounds.width / 2 + tooltipBounds.width / 2 > window.innerWidth - 40) {
                directionHor = 'right'
                left = bounds.right - tooltipBounds.width;
            } else if(bounds.left - tooltipSides < tooltipSides + 40) {
                directionHor = 'left';
                left = bounds.left;
            }
            app.$store.commit('tooltip/openTooltip', {
                left,
                top,
                posHor: directionHor,
                posVer: directionVer
            });
        })

        showTimeout = null;
    }, 1200);
}
const mouseOut = () => {
    if(showTimeout) {
        clearTimeout(showTimeout);
        showTimeout = null;
    }
    app.$store.commit('tooltip/closeTooltip');
}

const elMap = new Map<HTMLElement, EventListenerOrEventListenerObject>();

export default class implements DirectiveOptions {
    inserted: DirectiveFunction = (el, binding,..._) => {
        if(!tooltip) {
            tooltip = document.querySelector('#base-tooltip');
        }

        if(!onscrollActive) {
            window.addEventListener('scroll', () => mouseOut());
            window.addEventListener('resize', () => mouseOut());
            onscrollActive = true;
        }

        const eventFunc = () => {
            mouseIn(el, binding, ..._);
        };
        elMap.set(el, eventFunc);
        el.addEventListener('mouseenter', eventFunc);
        el.addEventListener('mouseleave', mouseOut);
    }
    unbind: DirectiveFunction = (el) => {
        mouseOut();
        if(showTimeout) {
            clearTimeout(showTimeout);
            showTimeout = null;
        }
        let ev = elMap.get(el);
        if(ev) {
            el.removeEventListener('mouseenter', ev);
            elMap.delete(el);
        }
        el.removeEventListener('mouseleave', mouseOut);
    }
};
