import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { authInit } from '../helpers/api'
import {roles} from "../config/navigation";
import {USER_DEFAULT_ROLE} from "@/config/user.config";

Vue.use(Router)

authInit(store)

const routes = [
    {
        path: '/',
        component: () => import ('@/containers/TheContainerNew'),
        children: [
            {
                path: '',
                name: 'ClientCard',
                component: () => import ('@/components/pages/HomeProxy.vue'),
                meta: {
                    pageTitle: 'Карточка клиента',
                    protected: true,
                    roles: [roles.admin, roles.manager, roles.operator]
                }
            },

            {
                path: '/admin-home',
                name: 'AdminHomePage',
                component: () => import ('@/components/pages/AdminHomePage/index'),
                meta: {
                    pageTitle: 'Главная страница',
                    protected: true,
                    roles: [roles.admin]
                }
            },

            {
                path: '/app-types',
                name: 'AppType',
                component: () => import ('@/views/admin/AppType'),
                meta: {
                    pageTitle: 'Типы обращений',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/marketing-source',
                name: 'MarketingSource',
                component: () => import ('@/views/admin/MarketingSource'),
                meta: {
                    pageTitle: 'Источники маркетинга',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/branches',
                name: 'Branch',
                component: () => import ('@/views/admin/branch/Branch'),
                meta: {
                    pageTitle: 'Филиалы',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/branches/create',
                name: 'BranchFormCreate',
                component: () => import ('@/views/admin/branch/BranchForm'),
                meta: {
                    pageTitle: 'Добавить филиал',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/branches/edit/:id',
                name: 'BranchFormEdit',
                component: () => import ('@/views/admin/branch/BranchForm'),
                meta: {
                    pageTitle: 'Изменить филиал',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/branches/:id',
                name: 'BranchShow',
                component: () => import ('@/views/admin/branch/BranchShow'),
                meta: {
                    pageTitle: 'Информация о филиале',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/customers',
                name: 'Customer',
                component: () => import ('@/views/admin/Customer/Customer'),
                meta: {
                    pageTitle: 'Клиенты',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/customers/new',
                name: 'CustomerFormNew',
                component: () => import ('@/views/admin/Customer/CustomerForm'),
                meta: {
                    pageTitle: 'Создать нового клиента',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/customers/edit/:id',
                name: 'CustomerFormByID',
                component: () => import ('@/views/admin/Customer/UpdateCustomer'),
                meta: {
                    pageTitle: 'Редактирование данных клиента',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/products',
                name: 'Product',
                component: () => import ('@/views/admin/Product'),
                meta: {
                    pageTitle: 'Продукты банка',
                    protected: true,
                    roles: [roles.admin, roles.manager]
                }
            },
            {
                path: '/products/new',
                name: 'ProductFormNew',
                component: () => import ('@/views/admin/ProductForm'),
                meta: {
                    pageTitle: 'Форма продукта',
                    protected: true,
                    roles: [roles.admin, roles.manager]
                }
            },
            {
                path: '/products/:id',
                name: 'ProductFormByID',
                component: () => import ('@/views/admin/ProductForm'),
                meta: {
                    pageTitle: 'Форма продукта',
                    protected: true,
                    roles: [roles.admin, roles.manager]
                }
            },
            {
                path: '/users',
                name: 'User',
                component: () => import ('@/views/admin/User'),
                meta: {
                    pageTitle: 'Пользователи',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/roles',
                name: 'Role',
                component: () => import ('@/views/admin/Role'),
                meta: {
                    pageTitle: 'Роли',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/options',
                name: 'Option',
                component: () => import ('@/views/admin/Option'),
                meta: {
                    pageTitle: 'Опции',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/script-category',
                name: 'ScriptCategory',
                component: () => import('../views/admin/ScriptCategory'),
                meta: {
                    pageTitle: 'Категория скриптов',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/script-text',
                name: 'ScriptText',
                component: () => import('../views/admin/ScriptText'),
                meta: {
                    pageTitle: 'Скрипты',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            {
                path: '/open-apps',
                name: 'Application',
                component: () => import('../views/admin/Application'),
                meta: {
                    pageTitle: 'Обращения',
                    protected: true,
                    roles: [roles.admin, roles.manager],
                    keepAlive:true
                }
            },
            {
                path: '/open-apps/:id',
                name: 'ApplicationForm',
                component: () => import('../views/admin/ApplicationForm'),
                meta: {
                    pageTitle: 'Просмотр обращений',
                    protected: true,
                    roles: [roles.admin, roles.manager]
                }
            },
            {
                path: '/open-apps-dib',
                name: 'ApplicationDIB',
                component: () => import('../views/admin/ApplicationDIB'),
                meta: {
                    pageTitle: 'Обращения',
                    protected: true,
                    roles: [roles.admin, roles.dib],
                    keepAlive:true
                }
            },
            {
                path: '/open-apps-dib/:id',
                name: 'ApplicationFormDIB',
                component: () => import('../views/admin/ApplicationFormDIB'),
                meta: {
                    pageTitle: 'Просмотр обращений',
                    protected: true,
                    roles: [roles.admin, roles.dib]
                }
            },
            {
                path: '/csi',
                name: 'CSI',
                component: () => import('../views/admin/CSI'),
                meta: {
                    pageTitle: 'CSI',
                    protected: true,
                    roles: [roles.admin]
                }
            },
            // marketing bot pages
            {
                path: '/bank-products',
                name: 'BankProducts',
                component: () => import('../views/admin/BankProducts'),
                meta: {
                    pageTitle: 'Список продуктов банка',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },
            {
                path: '/bank-products/add',
                name: 'BankProductsFormAdd',
                component: () => import('../views/admin/BankProductsForm'),
                meta: {
                    pageTitle: 'Добавление продукта банка',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },
            {
                path: '/bank-products/:id',
                name: 'BankProductsFormByID',
                component: () => import('../views/admin/BankProductsForm'),
                meta: {
                    pageTitle: 'Редатирование продукта банка',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },
            {
                path: '/promo-channels',
                name: 'PromoChannels',
                component: () => import('../views/admin/PromoChannels'),
                meta: {
                    pageTitle: 'Список рекламных каналов',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },
            {
                path: '/lead-list',
                name: 'LeadList',
                component: () => import('../views/admin/LeadList'),
                meta: {
                    pageTitle: 'Список лидов',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },
            {
                path: '/promo-companies',
                name: 'PromoCompanies',
                component: () => import('../views/admin/PromoCompanies.vue'),
                meta: {
                    pageTitle: 'Список рекламных кампаний',
                    protected: true,
                    roles: [roles.admin, roles.marketing]
                }
            },

        ],
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages404',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '404',
                name: 'Page404',
                component: () => import ('@/views/pages/Page404')
            },
            {
                path: '500',
                name: 'Page500',
                component: () => import ('@/views/pages/Page500')
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import ('@/views/pages/Login')
            }
        ]
    },
    {
        path: '/mypage',
        name: 'Pages',
        component: () => import ('@/views/mypage')
    }
]

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes
});

function getUserRoleOrDefault(role) {
    if (Object.values(roles).includes(role)) {
        return role;
    }

    return USER_DEFAULT_ROLE;
}

function findFirstAccessiblyRoute(routesParam, role) {
    for (const route of routesParam) {
        if (route.meta && route.meta.roles.includes(role)) {
            return route
        } else if (route.children) {
            return findFirstAccessiblyRoute(route.children, role)
        }
    }
}

router.beforeEach((to, from, next) => {
    const USER_ROLE = getUserRoleOrDefault(store.state.auth.role);

    if (store.state.isOperatorPickedUpPhone || (store.state.auth.authenticated && to.name === "Login")) {
        next(false)
    } else {
        if (to.meta.protected && store.state.auth.authenticated) {
            if (to.meta.roles === undefined) {
                next()
            } else if (to.meta.roles.includes(USER_ROLE)) {
                next()
            } else {
                // если пользователь переходит на страницу которая недоступна, находим первую ему доступную и редиректим
                const accessiblyRoute = findFirstAccessiblyRoute(routes, USER_ROLE);
                accessiblyRoute && next(accessiblyRoute.path)
            }
        } else if (to.meta.protected === false || to.meta.protected === undefined) {
            next()
        } else {
            next('/pages/login')
        }
    }
})

export default router
