import moment from "moment"

moment.defineLocale('uz', {
    months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"],
    monthsShort: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"],
    weekdays: ["Yakshanba", "Dushanba", "Seshanba", "Сhorshanba", "Payshanba", "Juma", "Shanba"],
    weekdaysShort: ["Yaksh", "Dush", "Sesh", "Chor", "Pay", "Jum", "Shan"],
    weekdaysMin: ["Yak", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'D MMMM YYYY, dddd HH:mm',
    },
    calendar: {
        sameDay: '[Bugun soat] LT [da]',
        nextDay: '[Ertaga] LT [da]',
        nextWeek: 'dddd [kuni soat] LT [da]',
        lastDay: '[Kecha soat] LT [da]',
        lastWeek: '[O\'tgan] dddd [kuni soat] LT [da]',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'Yakin %s ichida',
        past: 'bir necha %s oldin',
        s: 'fursat',
        ss: '%d fursat',
        m: 'bir daqiqa',
        mm: '%d daqiqa',
        h: 'bir soat',
        hh: '%d soat',
        d: 'bir kun',
        dd: '%d kun',
        M: 'bir oy',
        MM: '%d oy',
        y: 'bir yil',
        yy: '%d yil',
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 7, // The week that contains Jan 4th is the first week of the year.
    },
})
