import axios from 'axios'
import store from '../store'
import router from "../router";
import {EventBus} from "./eventBus";
import sAPIUrl from "../config/api";
import SecureLS from "secure-ls";

export const TOKEN_NAME = 'auth_token';

const intranetClient = axios.create({
    baseURL: window.location.origin + '/intranet-api'
})

const instance = axios.create({
    baseURL: sAPIUrl + 'api'
})
const sUserKey = 'user';
const oSecureLS = new SecureLS({encodingType: 'aes', encryptionSecret: 'my-asaka-kc-1152'});

// before a request is made start the nprogress
// instance.interceptors.request.use(config => {
//     return config;
// })

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
    return response
})
instance.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        logout();
    } else {
        return Promise.reject({
            message: error,
            data: error.response.data
        })
    }
})
const get = makeActionDecorator(function (url, params) {
    return instance({
        method: 'GET',
        url,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
        ...params,
    })
});

const post = makeActionDecorator(function (url, payload) {
    return instance({
        method: 'POST',
        url,
        data: payload,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
    })
});

const put = makeActionDecorator(function (url, payload) {
    return instance({
        method: 'PUT',
        url,
        data: payload,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
    });
});


const getUser = makeActionDecorator(function () {
    const url = '/user';
    return instance({
        method: 'GET',
        url,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
    });
});

const getAvatarUrl = (url) => {
    return `${sAPIUrl}storage/${url}`;
};

// delete is reserved keyword
const del = makeActionDecorator(function (url, payload) {
    const config = {
        method: 'DELETE',
        url,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
    };

    if (payload !== undefined) {
        config.data = payload;
    }

    return instance(config);
});

function login(url, payload) {
    return instance({
        method: 'POST',
        url,
        data: payload,
    })
}

function logout() {
    store.commit('LOGGED_OUT');
    store.commit('operator_info', {});
    oSecureLS.removeAll();
    EventBus.$emit('system-logout');
    sessionStorage.removeItem(TOKEN_NAME);
    setTimeout(() => {
        router.push('/pages/login').then(() => {
            window.location.href = "/"
        });
    }, 500);
}

const setSticker = makeActionDecorator(function(sticker) {
    return instance({
        method: 'POST',
        url: '/profile/sticker',
        data: sticker,
        headers: {
            Authorization: `Bearer ${store.state.auth.token}`,
        },
    });
})

function authInit(store) {
    const {token, role, username, phone} = getDataFromStore();
    if (token && role && phone && username) {
        store.commit('LOGGED_IN', {token, role, username, phone})
    }
}

function authCheck() {
    const sessionToken = sessionStorage.getItem(TOKEN_NAME);
    const {token, role, username, phone, password} = getDataFromStore();
    if (!token && !role && !phone && !username || !sessionToken) {
        return false
    }

    return true;
}

function storeData(value) {
    sessionStorage.setItem(TOKEN_NAME, '+');

    oSecureLS.set(sUserKey, value);
}

function getDataFromStore() {
    const oDefault = {
        token: false,
        role: false,
        username: false,
        phone: false,
        password: false
    };
    return oSecureLS.get(sUserKey) ?? oDefault;
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = `expires=${d.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
}

function getCookie(cname) {
    const name = `${cname}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            const value = c.substring(name.length, c.length);
            return value === 'null' ? null : value;
        }
    }
    return ''
}


function makeActionDecorator(fTargetFunction) {
    return function () {
        if (!store.state.auth.token) {
            authInit(store);
        }
        return fTargetFunction.apply(this, arguments);
    }
}

export {
    get,
    post,
    del,
    put,
    login,
    authInit,
    setCookie,
    getCookie,
    authCheck,
    logout,
    getUser,
    getAvatarUrl,
    sAPIUrl,
    storeData,
    getDataFromStore,
    setSticker,
    intranetClient,
    oSecureLS,
    sUserKey
}
